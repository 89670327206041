import * as React from 'react';

import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

import constants from '../constants';

const SupportPage = () => {

  const { t } = useTranslation();

  React.useEffect(() => {
    window.location.replace(constants.SUPPORT);
  }, [])

  return (
    <Layout >
      <Seo
        title={"Support"}
        description={t("SupportText")}
      />
      <div className="max-w-md mx-auto overflow-hidden h-full justify-center items-center self-center flex rounded-xl">
        <div className="p-6 bg-grey-light rounded-xl dark:bg-grey-dark shadow-md">
          <div className="tracking-wide font-semibold p-4 self-center text-center text-text-light dark:text-text-dark">
            {t("SupportRedirectText")}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SupportPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;